import React from "react";

import { PortableText } from "@portabletext/react";
import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { BlockAboutUs } from "../components/block-abaout-us";
import { Container } from "../components/container";
import { Cta } from "../components/cta";
import { ImageHome } from "../components/image-home";
import { MarqueeBrands } from "../components/marquee-brands";
import { Seo } from "../components/seo";
import { Title } from "../components/title";
import styles from "../styles/index.css";
import { ButtonSpecial } from "../ui/button-special";
import { BlobImage } from "../ui/image-blob/index";
import { TypeWrite } from "../ui/typewrite";

export default function IndexPage({ data: { page } }: PageProps<any>) {
  return (
    <>
      <Seo
        description={page.seo.description}
        image={page.seo.image?.asset.gatsbyImageData.images.fallback.src}
        title={page.seo.title}
      />

      <Container variant="home">
        <div>
          <h1
            aria-label={`${page.heroTitle} ${page.heroTypewrite[0].word}`}
            className={styles.heroTitle}
          >
            {page.heroTitle}{" "}
            <TypeWrite
              className={styles.titleTypeWrite}
              words={page.heroTypewrite.map(({ word, color }: any) => ({ word, color: color.hex }))}
            />
          </h1>
          <p className={styles.heroDescription}>{page.heroDescription}</p>
          <ButtonSpecial text={page.heroButton} to="/presupuesto" variant="outline" />
        </div>
        <div className={styles.heroImage}>
          <ImageHome />
        </div>
      </Container>

      <Container variant="gradient">
        <Title
          pretitle={page.testimonySectionHeader.pretitle}
          subtitle={page.testimonySectionHeader.subtitle}
          text={page.testimonySectionHeader.title}
        />

        <div className={styles.testimonyWrap}>
          <GatsbyImage
            alt="Testimonios"
            className={styles.testimonyImage}
            image={page.testimonySectionImage.asset.gatsbyImageData}
          />

          <div>
            <PortableText
              components={{
                block: {
                  normal: ({ children }) => (
                    <blockquote className={styles.testimonyBlockquote}>“{children}”</blockquote>
                  ),
                },
                marks: {
                  strong: ({ children }) => (
                    <span className={styles.testimonyHighlighted}>{children}</span>
                  ),
                },
              }}
              value={page._rawTestimonySectionTestimony}
            />

            <p className={styles.testimonyAuthorName}>{page.testimonySectionAuthor}</p>
            <p className={styles.testimonyAuthorPosition}>{page.testimonySectionAuthorPosition}</p>
          </div>
        </div>

        <div className={styles.testimonyMarqueeContainer}>
          <MarqueeBrands className={styles.testimonyMarquee} />

          <div className={styles.testimonyWrapButton}>
            <ButtonSpecial text="Ver más testimonios" to="/testimonios" />
          </div>
        </div>
      </Container>

      <Container>
        <Title
          pretitle={page.solutionsSectionHeader.pretitle}
          subtitle={page.solutionsSectionHeader.subtitle}
          text={page.solutionsSectionHeader.title}
        />

        <div className={styles.solutionGrid}>
          {page.solutionsItems.map(({ _key, title, description, image, link }: any) => (
            <Link key={_key} className="cursor-maximized" to={link}>
              <div key={_key} className={styles.solutionWrap}>
                <div className={styles.solutionImage}>
                  <BlobImage
                    id={`image-hero-${_key}`}
                    image={<GatsbyImage alt={title} image={image.asset.gatsbyImageData} />}
                  />
                </div>
                <div className={styles.solutionTextWrap}>
                  <h3 className={styles.solutionTitle}>{title}</h3>
                  <p className={styles.solutionDescription}>{description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <Cta />
      </Container>

      <BlockAboutUs />
    </>
  );
}

export const query = graphql`
  query {
    page: sanityPageHome {
      seo {
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, width: 1200, height: 630)
          }
        }
      }
      heroTitle
      heroTypewrite {
        word
        color {
          hex
        }
      }
      heroDescription
      heroButton
      testimonySectionHeader {
        pretitle
        title
        subtitle
      }
      testimonySectionImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 400)
        }
      }
      _rawTestimonySectionTestimony
      testimonySectionAuthor
      testimonySectionAuthorPosition
      solutionsSectionHeader {
        pretitle
        title
        subtitle
      }
      solutionsItems {
        _key
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 600)
          }
        }
        link
      }
    }
  }
`;

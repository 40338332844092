import React from "react";

import cn from "clsx";
import { StaticImage } from "gatsby-plugin-image";

import styles from "./image-home.css";

export const ImageHome = () => {
  return (
    <div className={styles.imageContainer}>
      <StaticImage alt="Owl Studio" src="../../images/home/home_mano.png" />

      <div className={cn(styles.image, styles.animatedCity)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_miscellaneous2.png" />
      </div>

      <div className={cn(styles.image, styles.animatedCity)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_miscellaneous3.png" />
      </div>

      <div className={cn(styles.image, styles.animatedScreen4)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_pantalla4.png" />
      </div>
      <div className={cn(styles.image, styles.animatedScreen2)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_pantalla2.png" />
      </div>

      <div className={cn(styles.image, styles.animatedGear)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_engranajes.png" />
      </div>
      <div className={cn(styles.image, styles.animatedCity)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_estructura.png" />
      </div>

      <div className={cn(styles.image, styles.animatedCity)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_miscellaneous.png" />
      </div>
      <div className={cn(styles.image, styles.animatedScreen3)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_pantalla3.png" />
      </div>
      <div className={cn(styles.image, styles.animatedScreen1)}>
        <StaticImage alt="Owl Studio" src="../../images/home/home_pantalla1.png" />
      </div>
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";

import styles from "./typewrite.css";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  words: {
    word: string;
    color: string;
  }[];
}

export const TypeWrite: React.FC<Props> = ({ words, ...props }) => {
  const [wordTyping, setWordTyping] = useState({
    word: "",
    displayText: "",
    colorText: "",
    isDeleting: false,
    index: 0,
  });

  const { displayText, colorText } = wordTyping;

  const wordTypingRef = useRef(wordTyping);

  wordTypingRef.current = wordTyping;

  useEffect(() => {
    let runner: any;
    let wait: any;

    const autoTyping = () => {
      if (!wordTypingRef.current.isDeleting) {
        // reset
        setWordTyping((state) => ({
          ...state,
          displayText: "",
          colorText: words[state.index].color,
          word: words[state.index].word,
          index: state.index < words.length - 1 ? state.index + 1 : 0,
        }));
      }

      runner = setInterval(() => {
        // deleting
        if (wordTypingRef.current.isDeleting) {
          setWordTyping((state) => ({
            ...state,
            displayText: state.displayText.slice(0, -1),
          }));

          if (!wordTypingRef.current.displayText.length) {
            resetTyping(500);
          }

          return;
        }

        // typing
        setWordTyping((state) => ({
          ...state,
          displayText: state.displayText + state.word[state.displayText.length],
        }));

        if (wordTypingRef.current.displayText === wordTypingRef.current.word) {
          resetTyping(1200);
        }
      }, 120);
    };

    const resetTyping = async (delayTime: number) => {
      clearInterval(runner);

      wait = setTimeout(() => {
        setWordTyping((state) => ({
          ...state,
          isDeleting: !state.isDeleting,
        }));

        autoTyping();
      }, delayTime);
    };

    autoTyping();

    return () => {
      clearInterval(runner);
      clearTimeout(wait);
    };
  }, [words]);

  return (
    <span className={styles.word} style={{ color: colorText }} {...props}>
      {displayText}
    </span>
  );
};
